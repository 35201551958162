const Away = (props) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    version="1.1" 
    viewBox="84.39 103.13 1031.24 993.74"
    width='34'
    height='34'
    {...props}
  >
    <path d="m1012.5 515.62h-63.355l-259.45-259.45c8.4805-15.066 13.43-31.98 13.43-49.922 0-56.871-46.254-103.12-103.12-103.12s-103.12 46.254-103.12 103.12c0 18.023 4.9688 34.996 13.387 49.969l-259.41 259.41h-63.355c-56.871 0-103.12 46.254-103.12 103.12v375c0 56.871 46.254 103.12 103.12 103.12h825c56.871 0 103.12-46.254 103.12-103.12v-375c0-56.871-46.254-103.12-103.12-103.12zm-412.5-356.25c25.855 0 46.875 21.02 46.875 46.875 0 12.562-4.832 24.168-14.137 33.254-17.137 17.578-47.828 18.09-65.992-0.51172-8.7891-8.5703-13.621-20.18-13.621-32.738 0-25.855 21.02-46.875 46.875-46.875zm-49.922 136.57c15.066 8.4805 31.98 13.43 49.922 13.43 18.023 0 34.996-4.9688 49.969-13.387l219.64 219.64h-539.21zm509.3 697.8c0 25.855-21.02 46.875-46.875 46.875h-825c-25.855 0-46.875-21.02-46.875-46.875v-375c0-25.855 21.02-46.875 46.875-46.875h825c25.855 0 46.875 21.02 46.875 46.875zm-459.38-384.38c-108.54 0-196.88 88.332-196.88 196.88s88.332 196.88 196.88 196.88 196.88-88.332 196.88-196.88-88.332-196.88-196.88-196.88zm0 337.5c-77.527 0-140.62-63.098-140.62-140.62s63.098-140.62 140.62-140.62 140.62 63.098 140.62 140.62-63.098 140.62-140.62 140.62zm65.625-140.62c0 15.527-12.598 28.125-28.125 28.125h-37.5c-15.527 0-28.125-12.598-28.125-28.125v-75c0-15.527 12.598-28.125 28.125-28.125s28.125 12.598 28.125 28.125v46.875h9.375c15.527 0 28.125 12.598 28.125 28.125z"/>
  </svg>
);

export default Away;