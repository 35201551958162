import { makeStyles } from "@material-ui/core";
import { Grid, } from '@material-ui/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryProfileTypes,  } from 'store/auth/profile';
import Select from 'react-select';
import { updateCreatorStatus } from 'store/creator';
import { useAlert } from 'react-alert';
import { defaultAlertError } from 'utils/common/constants';

const useStyles = makeStyles((theme) => ({
  statusText: {
    alignContent: 'center',
    marginRight: 10,
    '& span': {
      fontSize: '0.8rem',
      lineHeight: 0,
      fontFamily: 'Figtree-Bold',
      color: theme.common.black,
    }
  },
  select: {
    position: 'relative',
    top: '1px',
    '& .select__control--is-focused': {
      borderColor: '#495EFF',
      boxShadow: '0 0 0 1px #495EFF',
    },
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
}));

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '24px',
    fontSize: '0.8rem',
    padding: '0px',
    width: '76px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 4px',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px',
    padding: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '2px',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '2px',
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '14px',
    marginTop: '0px',
    zIndex: 9999,
  }),
  option: (provided) => ({
    ...provided,
    padding: '3px 6px',
  }),
};

const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'away', label: 'Away' },
]

const getStatusOption = (isAway) => {
  return isAway ? statusOptions[1] : statusOptions[0];
}

const StatusDropdown = (props) => {
  const { isAway } = props;

  const classes = useStyles();

  const alert = useAlert();
  const queryClient = useQueryClient();

  const { mutate: onUpdateCreatorStatus } = useMutation({
    mutationFn: updateCreatorStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryProfileTypes.profile] });
    },
    onError: (err) => {
      alert.error(null, {...defaultAlertError, offsetError: true });
    },
  });

  return (
    <Grid 
      container 
      item
      direction='row'
      className={classes.select}
    >
      <Grid item className={classes.statusText}>
        <span>Status</span>
      </Grid>
      <Grid item className={classes.select}>
        <Select
          classNamePrefix='select'
          // placeholder=''
          value={getStatusOption(isAway)}
          isClearable={false}
          isMulti={false}
          isSearchable={false}
          options={statusOptions}
          styles={selectStyles}
          onChange={(option) => {
            onUpdateCreatorStatus({ is_active: option.value === 'active' });
          }}
        />
      </Grid>
    </Grid>
  );
};

export default StatusDropdown;
