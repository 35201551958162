import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAlert } from 'react-alert';
import { useDropzone } from 'react-dropzone';
import { useMutation } from '@tanstack/react-query';
import { generateUploadUrl, uploadFile } from 'store/upload';
import { defaultAlertError } from 'utils/common/constants';

const defaultOpts = {
  disabled: false,
  videoAccept: false,
  offsetError: false,
  errorCallback: undefined,
};

export const uploadErrorStatus = {
  invalidAttachmentType: 'invalid_attachment_type',
  tooManyAttachments: 'too_many_attachments',
  maxSizeExceeded: 'max_size_exceeded',
  serverError: 'server_error',
}

const useUpload = (onHandleChange, { disabled, videoAccept, offsetError, errorCallback } = defaultOpts) => {
  const alert = useAlert();
  const [fileData, setFileData] = useState(null);
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [fileDisplayName, setFileDisplayName] = useState(null);

  const { mutate: onUploadFile } = useMutation({
    mutationFn: uploadFile,
    onSuccess: () => {
      setUploadIsLoading(false);
      onHandleChange(fileData);
    },
    onError: () => {
      setUploadIsLoading(false);
      errorCallback && errorCallback(uploadErrorStatus.serverError);
      alert.error(null, {...defaultAlertError, offsetError });
    },
  });

  const { mutate: onGenerateUrl } = useMutation({
    mutationFn: generateUploadUrl,
    onSuccess: (url) => {
      onUploadFile({ url, fileData });
    },
    onError: () => {
      setUploadIsLoading(false);
      errorCallback && errorCallback(uploadErrorStatus.serverError);
      alert.error(null, {...defaultAlertError, offsetError });
    },
  });

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      const hasInvalidType = rejectedFiles.some((rejection) =>
        rejection.errors.some((error) => error.code === 'file-invalid-type')
      );

      if (hasInvalidType) {
        errorCallback && errorCallback(uploadErrorStatus.invalidAttachmentType);
        alert.error(null, { title: 'Something went wrong', body: 'Only image or video attachments are allowed.', offsetError })
      } else {
        errorCallback && errorCallback(uploadErrorStatus.tooManyAttachments);
        alert.error(null, { title: 'Something went wrong', body: 'Multiple attachments are not allowed.', offsetError })
      }
      
      return;
    }
    const [file] = acceptedFiles;

    if (!file) return;

    // file size > 150MB
    if (file.size > 150 * 1024 * 1024) {
      errorCallback && errorCallback(uploadErrorStatus.maxSizeExceeded);
      alert.error(null, { title: 'Something went wrong', body: 'The maximum attachment size allowed is 150MB.', offsetError })
      return;
    }

    // fileType suffix change for video/* types
    const parsedFileType = file.type.startsWith('video/') ? 'video/mp4' : file.type;

    const fileName = `${uuidv4()}.${parsedFileType.split('/')[1]}`;
    const fileType = parsedFileType;

    setUploadIsLoading(true);
    setFileDisplayName(file.name);

    const newFileData = {
      file,
      fileName,
      fileType,
      encodedFile: `${process.env.REACT_APP_S3_BUCKET_URL}/${fileName}`,
    };
    setFileData(newFileData);
    onGenerateUrl({ filename: fileName, fileType });

    // const reader = new FileReader();
    // reader.onabort = () => console.log('file reading was aborted');
    // reader.onerror = () => console.log('file reading has failed');
    // reader.onload = () => {
    //   setUploadIsLoading(true);
    //   const encodedFile = reader.result;

    //   const newFileData = { encodedFile, file, fileName, fileType };
    //   setFileData(newFileData);
    //   onGenerateUrl({ filename: fileName, fileType });
    // };

    // reader.readAsDataURL(file);
  }, []);

  const acceptVideoInput = videoAccept ? { 'video/*': ['.mp4', '.mov'] } : null;

  const { getRootProps, getInputProps } = useDropzone({
    disabled,
    onDrop,
    maxFiles: 1,
    accept: {
      'image/*': ['.png', '.jpeg', '.jpg', '.gif'],
      ...acceptVideoInput,
    },
  });

  return { getRootProps, getInputProps, uploadIsLoading, fileDisplayName };
};

export default useUpload;
