import { Grid, makeStyles } from "@material-ui/core"
import { QuestionPriceDisplay, QuestionTipPriceDisplay, QuestionCreatorAwayDisplay } from "components/QuestionPriceDisplay";

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    padding: 10,
    backgroundColor: 'white',
    borderRadius: 10,
  },
  rightText: {
    fontFamily: 'Figtree-Regular',
    fontSize: 14,
    lineHeight: '19.07px',
    maxWidth: 110,
    textAlign: 'center',
    marginLeft: 5,
    marginBottom: 0,
  },
  bottomTextContainer: {
    maxWidth: 280,
    marginTop: 5,
    marginBottom: 10,
    '& p': {
      fontFamily: 'Figtree-Regular',
      fontSize: 16,
      lineHeight: '21.79px',
      textAlign: 'center',
      margin: 0,
    }
  },
  bottomTextTipContainer: {
    maxWidth: '330px !important',
  },
}));

export const QuestionPriceCard = (props) => {
  const { formattedPrice } = props;

  const classes = useStyles();

  return (
    <Grid container item className={classes.outerContainer} justifyContent="center">
      <Grid container item justifyContent="center" alignItems="center">
        <Grid item>
          <QuestionPriceDisplay formattedPrice={formattedPrice} />
        </Grid>
        <p className={classes.rightText}>for one question and 2 follow ups</p>
      </Grid>
      <Grid item className={classes.bottomTextContainer}>
        <p>You are <strong>only charged</strong> when your question is answered.</p>
      </Grid>
    </Grid>
  )
}

export const QuestionTipPriceCard = (props) => {
  const { displayName } = props;

  const classes = useStyles();

  return (
    <Grid container item className={classes.outerContainer} justifyContent="center">
      <Grid container item justifyContent="center" alignItems="center">
        <Grid item>
          <QuestionTipPriceDisplay />
        </Grid>
      </Grid>
      <Grid item className={`${classes.bottomTextContainer} ${classes.bottomTextTipContainer}`}>
        <p>You can leave {displayName} a tip if you love their response!</p>
      </Grid>
    </Grid>
  )
}

export const QuestionCreatorAwayCard = (props) => {
  const { displayName } = props;

  const classes = useStyles();

  return (
    <Grid container item className={classes.outerContainer} justifyContent="center">
      <Grid container item justifyContent="center" alignItems="center">
        <Grid item>
          <QuestionCreatorAwayDisplay />
        </Grid>
      </Grid>
      <Grid item className={`${classes.bottomTextContainer} ${classes.bottomTextTipContainer}`}>
        <p>{displayName} is away.<br />Your question will be delivered when they return.</p>
      </Grid>
    </Grid>
  )
};