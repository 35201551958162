import { methods, fetchApi } from 'utils/apiConfig';
const { GET, POST } = methods;

const queryCustomerTypes = {
  questionStepData: 'questionStepData',
  answerStepData: 'answerStepData',
  trimmedAnswer: 'trimmedAnswer',
  wholeAnswerSet: 'wholeAnswerSet',
  creatorDataForTip: 'creatorDataForTip',
  tempUnansweredQuestion: 'tempUnansweredQuestion',
};

const fetchQuestionStepData = ({ signal, queryKey: [_, tag] }) =>
  fetchApi(`/customer/getAsqCreator/${tag}`, GET, {
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    signal,
  });

const fetchTempUnansweredQuestion = ({ signal, queryKey: [_, questionTrackingCode] }) =>
  fetchApi(`/customer_priority/getPreviewQuestion?qtc=${questionTrackingCode}`, GET, {
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    signal,
  });

const fetchAnswerStepData = ({ signal, queryKey: [_, questionTrackingCode] }) =>
  fetchApi(`/customer/getCustomerDataBy_question_tracking_code/${questionTrackingCode}`, GET, {
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    signal,
  });

const fetchTrimmedAnswer = ({ signal, queryKey: [_, questionTrackingCode] }) =>
  fetchApi(`/customer/getFirstAnswerTrimmed/${questionTrackingCode}`, GET, {
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    signal,
  });

const fetchWholeAnswerSet = ({ signal, queryKey: [_, questionTrackingCode] }) =>
  fetchApi(`/customer/getSession/${questionTrackingCode}`, GET, {
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    signal,
  });

const retrieveTempQuestionCustomerData = ({ questionTrackingCode }) =>
  fetchApi(`/customer_priority/retrieveQuestionData/${questionTrackingCode}`, GET, {
    headers: { chinawall: process.env.REACT_APP_CHINA_WALL },
  });

const generateQuestionCode = (data) =>
  fetchApi('/customer_priority/generateQuestionCode', POST, {
    headers: { chinawall: process.env.REACT_APP_CHINA_WALL },
    data,
  });

const triggerPriorityTracking = (data) =>
  fetchApi('/customer/triggerPriorityTracking', POST, {
    headers: { chinawall: process.env.REACT_APP_CHINA_WALL },
    data,
  });

const sendQuestion = (data) =>
  fetchApi('/customer/sendFirstQuestionToCreator', POST, {
    timeout: 0,
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    data,
  });

const sendFollowUpQuestion = (data) =>
  fetchApi('/customer/createFollowUpQuestion', POST, {
    timeout: 0,
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    data,
  });

const acceptAnswer = (data) =>
  fetchApi('/customer/accept_answer', POST, {
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    data,
  });

const sendFeedback = (data) =>
  fetchApi('/customer/sendFeedback', POST, {
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    data,
  });

const detectQuestionOriginLanguage = ({ tag, ...params }) =>
  fetchApi(`/google-translate-service/detect_matching_language/${tag}`, GET, {
    headers: { chinawall: process.env.REACT_APP_CHINA_WALL },
    params,
  });

const fetchCreatorDataForTip = ({ signal, queryKey: [_, tag] }) =>
  fetchApi(`/customer/tip_creator/${tag}`, GET, {
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    signal,
  });

const getThankBankSession = ({ signal, queryKey: [_, code, redirected_from] }) =>
  fetchApi(`/customer/thankBankSession/${code}?redirected_from=${redirected_from ?? ''}`, GET, {
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    signal,
  });

const saveThankBankSession = ({ code, data }) =>
  fetchApi(`/customer/thankBankSession/${code}`, POST, {
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    data,
  });

const completeThankBankSession = ({ code, data }) =>
  fetchApi(`/customer/thankBankSession/complete/${code}`, POST, {
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    data,
  });

const sendTrackCallTB5 = ({ code, data }) =>
  fetchApi(`/customer/thankBankSession/sendTrackCallTB5/${code}`, POST, {
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    data,
  });

const sendTrackCallTB7 = ({ code, data }) =>
  fetchApi(`/customer/thankBankSession/sendTrackCallTB7/${code}`, POST, {
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    data,
  });

  const trackAppCtaAudienceClick = (data) =>
    fetchApi('/customer/ctaAudienceClickTrackCall', POST, {
      headers: {
        chinawall: process.env.REACT_APP_CHINA_WALL,
      },
      data,
    });

export {
  queryCustomerTypes,
  fetchQuestionStepData,
  fetchTempUnansweredQuestion,
  fetchAnswerStepData,
  fetchTrimmedAnswer,
  fetchWholeAnswerSet,
  generateQuestionCode,
  retrieveTempQuestionCustomerData,
  sendQuestion,
  sendFollowUpQuestion,
  triggerPriorityTracking,
  acceptAnswer,
  sendFeedback,
  detectQuestionOriginLanguage,
  fetchCreatorDataForTip,
  getThankBankSession,
  saveThankBankSession,
  completeThankBankSession,
  sendTrackCallTB5,
  sendTrackCallTB7,
  trackAppCtaAudienceClick
};
