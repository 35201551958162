import { useEffect, useMemo } from 'react';
import { Button, CircularProgress, Grid, InputBase, makeStyles, useMediaQuery, useTheme} from "@material-ui/core"
import useUpload from 'hooks/upload';
import { TrashcanIcon, VideoIcon } from 'components/Icons';

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    marginBottom: 20,
  },
  questionInputContainer: {
    width: '100%',
    border: '1px solid #CCCCCC',
    borderRadius: 3,
    padding: 0,
    backgroundColor: 'white',
  },
  questionInputWithAttachemntContainer: {
    minHeight: 117,
    flex: 1,
  },
  root: {
    width: '100%',
    padding: 0,
  },
  labelContainer: {
    marginBottom: 5,
  },
  input: {
    padding: '10px 20px'
  },
  inputWithAttachment: {
    padding: 20,
  },
  inputError: {
    fontFamily: 'Figtree-Regular',
    fontSize: 12,
    lineHeight: '16.34px',
    color: '#D44444'
  },
  labelText: {
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '19.2px',
  },
  uploadIcon: {
    marginBottom: 10,
  },
  loadingIndicator: {
    color: 'white',
  },
  attachmentContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 3,
    border: '1px solid #495EFF',
    padding: 10,
    marginLeft: 10,
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      marginTop: 10,
    }
  },
  attachmentContent: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    }
  },
  attachmentTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  trashIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  attachmentTitle: {
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '21.33px',
    color: '#495EFF'
  },
  attachmentDescription: {
    fontFamily: 'Figtree-Regular',
    fontSize: 16,
    lineHeight: '21.33px',
    color: '#495EFF'
  },
  uploadIndicator: {
    position: 'absolute',
    backgroundColor: '#495EFF',
    width: 20,
    height: 20,
    textAlign: 'center',
    alignContent: 'center',
    borderRadius: '50%',
    fontFamily: 'Figtree-Regular',
    lineHeight: 1,
    color: 'white',
    right: '-5px',
    top: '-5px',
  },
}))

export const QuestionInputWithAttachment = (props) => {
  const {
    label,
    placeholder,
    errorMessage,
    handleFormImageChange,
    handleFormRemoveAttachments,
    handleFormAttachmentError, 
    imageUploadDisabled, 
    onAttachmentUploading,
    values,
    fieldProps,
    onBlur,
    disabled,
  } = props;

  const { breakpoints } = useTheme();
  const classes = useStyles();
  const mobileView = useMediaQuery(breakpoints.down('xs'));

  const isFileUploaded = useMemo(() => {
    return values.customer_attachment_image_encoded || values.customer_attachment_video_encoded;
  }, [values.customer_attachment_image_encoded, values.customer_attachment_video_encoded]);

  const {
    getRootProps: rootAttachmentProps,
    getInputProps: inputAttachmentProps,
    uploadIsLoading: attachmentUploadIsLoading,
    fileDisplayName: attachmentFileDisplayName,
  } = useUpload(
    handleFormImageChange({
      image_url: 'customer_attachment_image_url',
      image_file: 'customer_attachment_image_encoded',
      video_url: 'customer_attachment_video_url',
      video_file: 'customer_attachment_video_encoded',
    }),
    { disabled: imageUploadDisabled, videoAccept: true, errorCallback: handleFormAttachmentError },
  );

  useEffect(() => {
    onAttachmentUploading(attachmentUploadIsLoading);
  }, [attachmentUploadIsLoading, onAttachmentUploading])

  return (
    <Grid item container className={classes.outerContainer}>
      <Grid item container xs={12} direction="row" justifyContent="space-between" className={classes.labelContainer}>
        <Grid item className={classes.labelText}>
          {label}
        </Grid>
      </Grid>
      <Grid container item direction={mobileView ? 'column' : 'row'}>
        <Grid item className={`${classes.questionInputContainer} ${classes.questionInputWithAttachemntContainer}`}>
          <InputBase
            {...fieldProps}
            classes={{ root: classes.root, input: classes.inputWithAttachment }}
            placeholder={placeholder}
            multiline={true}
            onBlur={onBlur}
            disabled={disabled}
          />
        </Grid>
        <Grid item className={classes.attachmentContainer} {...rootAttachmentProps()}>
          <VideoIcon 
            className={classes.uploadIcon}
            fill="#495EFF"
            width={26}
          />
          <div className={classes.attachmentContent}>
            <div className={classes.attachmentTextContainer}>
              <span className={classes.attachmentTitle}>Ask by Video</span>
              {mobileView ? (
                <span className={classes.attachmentDescription}>Attach one video, photo or file.</span>
              ) : (
                <>
                  <span className={classes.attachmentDescription}>Attach one video,</span>
                  <span className={classes.attachmentDescription}>photo or file.</span>
                </>
              )}
            </div>
            {isFileUploaded && (
              <div className={classes.trashIconContainer}>
                <TrashcanIcon 
                  fill="#9e9e9e" 
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleFormRemoveAttachments();
                  }} 
                />
              </div>
            )}
          </div>
          <input {...inputAttachmentProps()} />
          {(isFileUploaded || attachmentUploadIsLoading) && (
            <span className={classes.uploadIndicator}>
              {attachmentUploadIsLoading ? (
                <CircularProgress thickness={4} size={10} classes={{ svg: classes.loadingIndicator }}/>
              ) : (
                1
              )}
            </span>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {errorMessage && <span className={classes.inputError}>{errorMessage}</span>}
      </Grid>
    </Grid>
  )
}

export const QuestionInput = (props) => {
  const {
    label,
    placeholder,
    errorMessage,
    fieldProps,
    onBlur,
    disabled,
  } = props;

  const classes = useStyles();

  return (
    <Grid item container className={classes.outerContainer}>
      <Grid item container xs={12} direction="row" justifyContent="space-between" className={classes.labelContainer}>
        <Grid item className={classes.labelText}>
          {label}
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.questionInputContainer} alignContent="space-between">
        <Grid item xs={12}>
          <InputBase
            {...fieldProps}
            classes={{ root: classes.root, input: classes.input }}
            placeholder={placeholder}
            multiline={true}
            error
            onBlur={onBlur}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {errorMessage && <span className={classes.inputError}>{errorMessage}</span>}
      </Grid>
    </Grid>
  )
}